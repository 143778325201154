import Vue from "vue";

const globalData = new Vue({
  data() {
    return {
      activity: {},
    };
  },
  computed: {},
  methods: {
    getActivity() {
      return this.activity;
    },
    setActivity(activity) {
      this.activity = activity;
      console.log("set", this.activity);
    },
  },
});

export default globalData;
