<style scoped>
.home {
  width: 100%;
  height: 100%;
  background: url("../assets/problemBg.jpg");
  background-size: 100% 100%;
}
.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: #fff;
  font-size: 23px;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: center;
}
.header .back img {
  position: absolute;
  top: 28px;
  left: 20px;
  width: 16pt;
  height: 16pt;
}
/* .btn {
  background: red;
} */
.header .btn {
  position: absolute;
  top: 28px;
  right: 20px;
  line-height: 23px;
  font-size: 20px;
}
.middle {
  display: inline-block;
  width: 0px;
  height: 100%;
  vertical-align: middle;
}
.body {
  width: 90%;
  /* margin: 3vh 5% 0; */
  /* height: 77vh; */
  height: calc(100vh - 120px);
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(208, 35, 36, 0.05);
  overflow-y: scroll;
}
.body .problemHeader {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.body .problemHeader .problemIndex {
  width: 100%;
  height: 50px;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #fafafa;
  text-align: left;
  padding: 10px;
}
.body .problemHeader .problemIndex > div {
  display: inline-block;
  vertical-align: top;
}
.body .problemHeader .problemIndex > div:first-child {
  width: 3px;
  height: 20px;
  margin-top: 4px;
  border: 2px solid rgb(169, 151, 141);
}
.body .problemHeader .problemIndex > div:nth-child(2) {
  margin-left: 7px;
}
.body .problemHeader .problemIndex > div:nth-child(3) {
  float: right;
  margin-right: 10px;
}
.body .problemHeader .problemTitle {
  font-size: 18px;
  font-weight: bold;
  padding: 15px;
  text-align: left;
}
.body .problemTime {
  width: 100%;
  height: 35px;
  text-align: left;
  font-size: 16px;
  box-sizing: border-box;
  padding-left: 10px;
}
.nextProblem {
  width: 80%;
  height: 40px;
  background: rgb(0, 201, 5);
  line-height: 40px;
  margin: 0 10%;
  color: #fff;
  font-size: 17px;
}
.problemChooseGrade .gradeValue {
  color: rgb(194, 255, 88);
  line-height: 150px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: linear-gradient(#fd504d, #ed2d32);
  font-weight: bold;
  margin: 10px auto 30px;
  font-size: 45px;
}
.problemChooseBox {
  width: 100%;
  padding: 0 15px;
  overflow-y: scroll;
}
.problemChooseBox .chooseLine {
  width: 100%;
  padding: 6px 15px;
  background: rgb(247, 249, 251);
  border: 1px solid rgb(241, 241, 244);
  color: rgb(90, 96, 99);
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
  letter-spacing: 2px;
  margin: 10px 0;
  font-size: 16px;
  /* text-overflow: ellipsis; */
  /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  /* white-space: nowrap;  */
  /*让文字不换行*/
  /* overflow: hidden; */
  /*超出要隐藏*/
  transition: 0.2s;
}
.companyAndSource {
  width: 100%;
  padding: 15px;
  text-align: left;
}
.companyAndSource > div > div {
  /* display: inline-block; */
  color: rgb(41, 151, 78);
  background: rgb(223, 244, 228);
  padding: 3px 7px;
  margin: 5px 0;
  font-size: 13px;
  border-radius: 2px;
}
.answerTip {
  color: rgb(169, 151, 141);
  width: 100px;
  float: right;
  font-size: 15px;
  margin: 10px 0;
}
</style>
<style scoped>
.lineErr {
  border: 1px solid #f00 !important;
  color: #f00;
}
.lineSuccess {
  border: 1px solid #0f0 !important;
  color: #0f0;
}
.lineChoose {
  border: 1px solid #f60 !important;
  color: #f60;
}
.title {
  /*超出部分隐藏*/
  overflow: hidden;
  /*强制在一行*/
  white-space: nowrap;
  /*文本超出部分省略*/
  text-overflow: ellipsis;
  width: 60%;
  justify-content: center;
}
textarea {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
  background-color: transparent;
  font-size: inherit;
  width: 100%;
}

textarea:focus {
  outline: none !important;
}

.mo-textarea {
  height: 100px;
  display: inline-block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  color: #606266;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #3677f0;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
/* 提示文字 */
.mo-textarea::placeholder {
  color: #c0c4cc;
}

/* 鼠标hover */
.mo-textarea:hover {
  border-color: #c0c4cc;
}

/* 获得焦点 */
.mo-textarea:focus {
  border-color: #0059ff;
}
</style>
<template>
  <div class="home">
    <!-- 头部信息 -->
    <div class="header">
      <div class="title">
        {{ activity.name }}
      </div>

      <div class="back">
        <div class="middle"></div>
        <img @click="back" src="../assets/back.png" alt="" />
      </div>
      <div v-if="type !== 'over'" class="btn">
        <div class="middle"></div>
        <span v-if="problems.length != index" @click="nextProblem">下一题</span>
        <span v-if="problems.length == index" @click="finish">提交</span>
      </div>
    </div>
    <div class="bodyScroll">
      <!-- 答题框信息 -->
      <div class="body">
        <div class="problemHeader">
          <div class="problemIndex">
            <div></div>
            <div>
              <span v-if="type == 'select'">单选题</span>
              <span v-if="type == 'multi'">多选题</span>
              <span v-if="type == 'check'">判断题</span>
              <span v-if="type == 'blank'">填空题</span>
              <span v-if="type == 'over'">答题结束</span>
            </div>
            <div>
              <span style="font-size: 30px">{{ index }}</span>
              <span style="color: #ccc">/{{ problems.length }}</span>
            </div>
          </div>
          <div v-if="type !== 'over'" class="problemTitle">
            <div class="middle"></div>
            <div style="display: inline-block">
              {{ problem.problem }}
            </div>
          </div>
          <div v-if="type != 'over'" class="problemChooseBox">
            <div
              v-for="(item, index) in problem.choose"
              :key="index"
              @click.stop="choose(index)"
              :class="chooseStyle[index]"
            >
              <span v-if="problem.type == 'select' || problem.type == 'multi'">
                {{ chooseNum[index] }}.</span
              >{{ item }}
            </div>
            <div v-if="problem.type == 'blank'">
              <textarea
                class="mo-textarea"
                placeholder="请输入内容"
                v-model="blankAnswer"
                id=""
                cols="30"
                rows="10"
              ></textarea>
            </div>
          </div>
          <div
            v-if="
              (type !== 'over' && problem.company && problem.company !== '') ||
              (type !== 'over' && problem.source && problem.source !== '')
            "
            class="companyAndSource"
          >
            <div v-if="problem.company && problem.company !== ''">
              <div style="display: inline-block">
                出题单位：{{ problem.company }}
              </div>
            </div>
            <div v-if="problem.source && problem.source !== ''">
              <div style="display: inline-block">
                题目来源：{{ problem.source }}
              </div>
            </div>
          </div>
          <div v-if="type == 'over'" class="problemChooseGrade">
            <div
              style="
                color: rgb(201, 37, 27);
                font-size: 16px;
                line-height: 40px;
                letter-spacing: 3px;
              "
            >
              您的分数为
            </div>
            <div class="gradeValue">{{ allResult }}分</div>
            <div
              class="nextProblem"
              style="background: rgb(255, 167, 14); border-radius: 5px"
              @click="goRanking"
            >
              查看排行
            </div>
            <div
              class="nextProblem"
              style="
                background: rgb(0, 201, 5);
                border-radius: 5px;
                margin-top: 15px;
              "
              @click="goWrong"
            >
              查看错题
            </div>
          </div>
          <!-- <div class="answerTip">
            <i
              style="margin-right:5px"
              class="fa fa-lightbulb-o"
              aria-hidden="true"
            ></i
            >查看提示
          </div> -->
        </div>
      </div>
    </div>
    <!-- 答题框信息 -->
    <!-- <div class="body">
      <div class="problemHeader">
        <div class="problemIndex">
          <div>
            <span style="color:rgb(178,216,0)">{{ index }}</span>
            /共{{ problems.length }}题
            <span style="margin-left:20px;">
              <span>倒计时：</span>
              <span style="color:rgb(255, 84, 80)">{{ countDown }}</span>
            </span>
          </div>
          <div>
            <span v-if="type == 'select'">单选题</span>
            <span v-if="type == 'multi'">多选题</span>
            <span v-if="type == 'check'">判断题</span>
            <span v-if="type == 'fill'">填空</span>
            <span v-if="type == 'over'">已完成</span>
          </div>
        </div>
        <div class="problemTitle">
          <div class="middle"></div>
          <div v-if="type !== 'over'" style="display:inline-block">
            {{ problem.problem }}
          </div>
          <div
            v-if="type == 'over'"
            style="display:inline-block;color:#00c905;font-size:30px;letter-spacing: 3px;"
          >
            答题结束
          </div>
        </div>
      </div>
      <div class="problemBody">
        <div v-if="type !== 'over'" class="problemChooseBox">
          <div
            @click="choose(index)"
            v-for="(item, index) in problem.choose"
            :key="index"
            :class="chooseStyle[index]"
          >
            <span v-if="problem.type == 'select' || problem.type == 'multi'">
              {{ chooseNum[index] }}.</span
            >{{ item }}
          </div>
        </div>
        <div v-if="type !== 'over'" class="problemChooseSubmit">
          <div
            @click="nextProblem"
            v-if="problems.length !== index"
            class="nextProblem"
          >
            下一题
          </div>
          <div
            v-if="problems.length == index"
            class="nextProblem"
            style="background:rgb(253,70,73)"
            @click="finish"
          >
            确认提交
          </div>
        </div>
        <div class="problemChooseGrade">
          <div
            style="color:rgb(201,37,27);font-size:16px;line-height:40px;letter-spacing: 3px;"
          >
            您的分数为
          </div>
          <div class="gradeValue">{{ allResult }}分</div>
          <div
            class="nextProblem"
            style="background:rgb(255,167,14);border-radius:5px;"
            @click="goRanking"
          >
            查看排行
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div
            class="nextProblem"
            style="background:rgb(214,214,214);border-radius:5px;"
            @click="goRanking"
          >
            查看排行
          </div> -->
  </div>
</template>

<script>
/* eslint-disable */
import _ from "lodash";
import { wxConPost } from "../api/httpApi"; // Data request API
import globalData from "../api/globalData"; // Data request API
export default {
  name: "Home",
  data () {
    return {
      activityId: "",
      problems: [],
      problem: {},
      index: 1, // 正在作答的题目的顺序
      type: "over", // 正在作答的题目类型
      chooseStyle: [], // 选项的页面格式
      beginDate: 0, // 答题开始时间戳
      countDown: 0, // 倒计时
      allResult: 0, // 总成绩
      chooseNum: ["A", "B", "C", "D", "E", "F"], // 题目序列号
      selectAnswer: "", // 单选题答案
      multiAnswer: [], // 多选题答案
      checkAnswer: "", // 判断题答案
      blankAnswer: "", // 填空题答案
      interval: {},
      activity: {}, // 正在参与的活动

      wrongTopics: [], //错题
      wrongSelect: [], //错误选项
    };
  },
  components: {},
  methods: {
    // 计算总分
    getpaperScore () {
      const allProblems = this.problems;
      let paperScore = 0;
      for (const iterator of allProblems) {
        paperScore += parseFloat(iterator.score);
      }
      return paperScore;
    },
    // 查看排行榜
    goRanking () {
      this.$router.replace("/ranking-list");
    },
    // 查看错题
    goWrong () {
      this.$router.push({
        path: "/wrong-topics",
        query: {
          wrongTopics: this.wrongTopics,
          wrongSelect: this.wrongSelect,
        },
      });
    },
    // 结束答题
    async finish () {
      // 首先判断最后一道题目
      await this.checkProblem();
      // 结束计时
      clearInterval(this.interval);
      const overTime = new Date().getTime();
      const paperScore = this.getpaperScore();
      // 右上角显示完成
      this.type = "over";
      // this.problem.problem = "答题结束";
      // 向后台提交结束请求报告分数值
      const result = await wxConPost("/services/gttt-answer-exam/exam/submit", {
        id: this.activityId,
        score: this.allResult,
        timeMs: overTime - this.beginDate,
        paperScore, // 试题总分数
      });
    },
    // 下一题
    async nextProblem () {
      // 判断题型  选择题判断是否选中了答案  填空题判断填空中是否有字符串
      await this.checkProblem();
      // 初始化答案
      this.selectAnswer = "";
      this.multiAnswer = [];
      this.checkAnswer = "";
      this.blankAnswer = ""
      this.index++;
      this.problem = this.problems[this.index - 1];
      this.type = this.problem.type;
      const mewChooseStyle = [];
      if (this.problem.choose) {
        for (const item of this.problem.choose) {
          mewChooseStyle.push("chooseLine");
        }
      }
      this.chooseStyle = mewChooseStyle;
    },
    // 判断题目进行加分
    async checkProblem () {
      if (this.problem.type == "select") {
        // 单选题判断数组第一个即可
        if (this.selectAnswer == this.problem.answer[0]) {
          this.allResult = this.allResult + parseInt(this.problem.score);
        }
        // hzj新增
        else {
          this.wrongTopics.push(this.problem);
          this.wrongSelect.push(this.selectAnswer);
        }
      } else if (this.problem.type == "multi") {
        // 多选题判断  首先判断答案位数是否一样
        if (this.problem.answer.length == this.multiAnswer.length) {
          // 一致时先进行排序，然后进行比较
          const newAnswer = this.problem.answer.sort();
          const newDoAnswer = this.multiAnswer.sort();
          if (newAnswer.join("") == newDoAnswer.join("")) {
            this.allResult = this.allResult + parseInt(this.problem.score);
          }
        }
        // hzj新增
        else {
          this.wrongTopics.push(this.problem);
          this.wrongSelect.push(this.multiAnswer);
        }
      } else if (this.problem.type == "check") {
        // 判断题直接判断答案即可
        if (this.checkAnswer == this.problem.answer[0]) {
          this.allResult = this.allResult + parseInt(this.problem.score);
        }
        // hzj新增
        else {
          this.wrongTopics.push(this.problem);
          this.wrongSelect.push(this.checkAnswer);
        }
      } else if (this.problem.type == "blank") {
        // 填空题直接判断答案即可
        if (this.blankAnswer.trim() == this.problem.answer[0]) {
          this.allResult = this.allResult + parseInt(this.problem.score);
        }
        // hzj新增
        else {
          this.wrongTopics.push(this.problem);
          this.wrongSelect.push(this.checkAnswer);
        }
      }
    },
    // 返回上一页
    back () {
      this.$router.replace("/activity-main");
    },
    // 选择题答案(选项序号，选项)
    choose (answerIndex) {
      console.log("---", answerIndex);
      // 如果为单选题
      if (this.problem.type == "select") {
        const newChooseStyle = [];
        for (const index in this.chooseStyle) {
          if (answerIndex == index) {
            newChooseStyle.push("chooseLine lineChoose");
          } else {
            newChooseStyle.push("chooseLine");
          }
        }
        this.chooseStyle = newChooseStyle;
        this.selectAnswer = this.chooseNum[answerIndex];
      } else if (this.problem.type == "multi") {
        // 如果为多选题
        const index = _.indexOf(this.multiAnswer, this.chooseNum[answerIndex]);
        if (index !== -1) {
          this.multiAnswer.splice(index, 1);
          this.chooseStyle[answerIndex] = "chooseLine";
        } else {
          this.multiAnswer.push(this.chooseNum[answerIndex]);
          this.chooseStyle[answerIndex] = "chooseLine lineChoose";
        }
        this.$forceUpdate();
      } else if (this.problem.type == "check") {
        // 如果为判断题
        const newChooseStyle = [];
        for (const index in this.chooseStyle) {
          if (answerIndex == index) {
            newChooseStyle.push("chooseLine lineChoose");
          } else {
            newChooseStyle.push("chooseLine");
          }
        }
        this.chooseStyle = newChooseStyle;
        this.checkAnswer = this.problem.choose[answerIndex];
      } else if (this.problem.type == "blank") {
        // 如果为判断题
        this.blankAnswer = this.problem.choose[answerIndex];
      }
    },
  },
  async created () {
    this.activity = globalData.getActivity();
    if (!_.isEmpty(this.activity)) {
      this.activityId = this.activity.id;
      const result = await wxConPost(
        "/services/gttt-answer-exam/exam/getPaper",
        { id: this.activityId }
      );
      if (result.ret) {
        // 去查接口  返回一组题目
        this.problems = result.ret;
        if (this.problems.length > 0) {
          this.problem = this.problems[0];
          this.index = 1;
          this.chooseStyle = [];
          this.beginDate = new Date().getTime();
          // this.countDown = 1 * 60 * 30;
          this.countDown = result.examTimeMs / 1000;
          this.type = this.problem.type;
          // 倒计时暂不显示
          const tt = this;
          this.interval = setInterval(() => {
            if (tt.countDown == 0) {
              tt.finish();
            } else {
              tt.countDown--;
            }
          }, 1000);
          if (this.problem.choose) {
            for (const item of this.problem.choose) {
              this.chooseStyle.push("chooseLine");
            }
          }
        }
      }
    } else {
      this.back();
    }
  },
};
</script>
